import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {
  domains = [];

  listLoading = true;
  createLoading = false;

  createError = ""

  newDomainName = ""


  createMode = false;

  showCreateDrawer(): void {
    this.createMode = true;
  }

  closeCreateDrawer(): void {
    this.createMode = false;
    this.newDomainName = ""
    this.createError =""
  }

  constructor(private api: ApiService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.getDomains()
    
  }

  getDomains() {
    this.api.get("domains").subscribe((data: any) => {
      this.listLoading = false;
      this.domains = data
  })
  }

  addDomain(){
    this.createLoading = true;
    if (this.newDomainName.length ==0) {
      this.createError = "Please enter a valid domain name"
      return 
    }

    let payload = {
      "name": this.newDomainName
    }
    this.api.set("domains",payload).subscribe(
      (data: any) => {
      this.createLoading = false;      
      this.closeCreateDrawer()
      this.getDomains()
  },
      (error) => {
        if (error.status==200) { //False detection
          this.createLoading = false;      
          this.closeCreateDrawer()
          this.getDomains()
          return
        }
        console.log(error)
        this.createLoading = false;        
        this.createError = error.error 
      }
  )

  }

  cancel(): void {}

  notify(message:string){
    const id = this.message.loading(message, 
      { nzDuration: 0 }).messageId;
    setTimeout(() => {
      this.message.remove(id);
    }, 2500);
  }

  deleteDomain(id: string){
    this.listLoading = true 
    this.api.delete("domain/"+id).subscribe(
      (data: any) => {
          this.listLoading = false;
          this.getDomains()
                      },
      (error: any) => {
          this.listLoading = false;

          if (error.status!=200) {
            this.notify(error.error)
            return 
          }        
          this.getDomains()  
                      }                      
  )

    
  }

  verifyDomain(id: string){
    this.api.set("domain/"+id, {}, "put").subscribe(
      (data: any) => {
        this.notify("Domain verified successfully")
         this.getDomains()         
                      },
      (error: any) => {          
          if (error.status!=200) {
            this.notify(error.error)
            return 
          }   
          this.notify("Domain verified successfully")
          this.getDomains()               
                      }                      
  )

  }

}
