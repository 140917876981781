import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from './auth.service'
import { ActivatedRoute } from '@angular/router';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
constructor(private message: NzMessageService, 
            private router: Router, 
            private auth: AuthService, 
            private route: ActivatedRoute) {}


ngOnInit() {
}


intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  // const authToken = this.cookieService.get('co-at')
  // const authToken = this.auth.getToken();
  // console.log("authToken-->", authToken)

  let poolData = {
    UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
    ClientId: environment.cognitoAppClientId // Your client id here
  };

  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    cognitoUser.getSession((err: any, session: any) => {

      if (session) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + session["accessToken"]["jwtToken"]
          }
        });

      }
      // if (err) {
      //   console.log("GETTOKEN- FAILED-->", err.message)
      // }
      //console.log("SESSION",session)
      return session
      //return session["accessToken"]["jwtToken"]
    })
  }

  // request = request.clone({
  //   setHeaders: {
  //     Authorization: 'Bearer ' + authToken
  //   }
  // });
  return next.handle(request).pipe( tap(() => {},
    (err: any) => {
    if (err instanceof HttpErrorResponse) {
      if (err.status !== 401) {
       return;
      }      
      this.auth.signOut();     
    }
  }));
}



}