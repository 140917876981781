import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service'
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient, private auth: AuthService,private message: NzMessageService) { }

  get(endpoint: string){
    this.message.create("loading","Please wait")
    return this.httpClient.get(`${environment.api_host}/`+ endpoint)
  }

  set(endpoint: string, payload: any, type:string="post"){
    this.message.create("loading","Please wait")
    if (type=="post"){            
      return this.httpClient.post(`${environment.api_host}/${endpoint}`, payload, {headers: {'Content-Type': 'application/json'}})
    }
    else if (type=="put"){
      return this.httpClient.put(`${environment.api_host}/${endpoint}`, payload, {headers: {'Content-Type': 'application/json'}})
    }
    
  }

  delete(endpoint: string){
    this.message.create("loading","Please wait")
    return this.httpClient.delete(`${environment.api_host}/${endpoint}`, {headers: {'Content-Type': 'application/json'}})

  }



 

}
