<div class="inner-content">
  <div nz-row>
    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="16">
      <nz-page-header class="site-page-header" nzTitle="Proxies">
        <nz-page-header-extra>           
          <a nz-button nzType="primary" (click)="showCreateDrawer()"><i nz-icon nzType="plus"></i> Create new proxy</a>          
          <button nz-button nzType="default" (click)="getProxies()"> <i nz-icon nzType="reload"></i>Refresh</button>
        </nz-page-header-extra>        
      </nz-page-header>

      <div class="inner-content">

        <nz-table #basicTable [nzData]="proxies" [nzLoading]="listLoading" >
          <thead>
            <tr>
              <th>Source</th>
              <th>Destination</th>  
              <th>Domain</th>              
              <th>&nbsp;Bandwidth
                <br> (Current month)
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let proxy of proxies">
                <td><a nz-tooltip nzTooltipTitle="Opens in a new tab" href="https://{{proxy.source}}" target="_blank">{{proxy.source}}</a></td>
                <td><a nz-tooltip nzTooltipTitle="Opens in a new tab" href="https://{{proxy.destination}}" target="_blank">{{proxy.destination}}</a></td>
                <td>{{proxy.domain_name}}</td>                             
                <td>&nbsp;&nbsp;&nbsp; {{proxy.bandwidth_usage}} GB</td>
                <td>                   
                    <a style="color:red" nzDanger nz-popconfirm
                    nzPopconfirmTitle="Are you sure you want to delete this proxy? Traffic will no longer be routed to the destination from this source"
                    nzPopconfirmPlacement="top" (nzOnConfirm)="deleteProxy(proxy.id)" (nzOnCancel)="cancel()">
                    Delete
                    </a>
                </td>
            </tr>
          </tbody>
        </nz-table>


      </div>
    </div>
    <div nz-col nzXs="0" nzSm="0" nzMd="8" nzLg="8" nzXl="8">
      <nz-card>
        <nz-alert nzType="info" nzMessage="From the team"
          nzDescription="Thank you for trying out BrandedSurf beta. If you need support, please reach us at beta@brandedsurf.com ">
        </nz-alert>
      </nz-card>
    </div>
  </div>
</div>

<!-- Add a new proxy -->
<nz-drawer
      [nzBodyStyle]="{ overflow: 'auto' }"
      [nzMaskClosable]="false"
      [nzWidth]="400"
      [nzVisible]="createMode"
      nzTitle="Create a new proxy"
      [nzFooter]="footerTpl"
      (nzOnClose)="closeCreateDrawer()"
    >
    <form nz-form *nzDrawerContent>
      <div nz-row [nzGutter]="12">
        <div nz-col nzSpan="24">  
                  
            <nz-alert nzType="info" 
            nzDescription="The base URL of destination domain should be one of the domains in your account and verified.">
      </nz-alert>                
      <br>
    <nz-form-item>
      <nz-form-label>Source domain</nz-form-label> &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
      <nz-form-control>
        <input nz-input placeholder="abc.example.com" [(ngModel)]="newSource" [ngModelOptions]="{standalone: true}" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label>Destination domain </nz-form-label> &nbsp;  &nbsp; 
        <nz-form-control>
          <input nz-input placeholder="yoursaas.com" [(ngModel)]="newDestination" [ngModelOptions]="{standalone: true}" />
        </nz-form-control>
      </nz-form-item>
    <br>
    <span nz-typography nzType="danger">{{createError}}</span>
        </div>       
      </div>    
    </form>
    

    <ng-template #footerTpl>
      <div style="float: right">
        <button nz-button style="margin-right: 8px;" (click)="closeCreateDrawer()">Cancel</button>
        <button nz-button nzType="primary" (click)="createProxy()">Create</button>
      </div>
    </ng-template>
  </nz-drawer>