<nz-layout class="layout">  
    <nz-header>      
      <div class="logo">
        <h1 style="color:white; font-family: 'Raleway', sans-serif;font-weight: 700; font-size:30px">branded.surf</h1>               
      </div>        
      <ul nz-menu dir="rtl" nzTheme="dark" nzMode="horizontal">
        <li nz-menu-item>Documentation</li>
        <li nz-menu-item>Support</li>               
      </ul> 
       
    </nz-header>
    <nz-content>    
        <router-outlet></router-outlet>      
            </nz-content>       

    
    <nz-footer>BrandedSurf ©2021 All rights reserved</nz-footer>
</nz-layout>