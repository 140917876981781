<br><br>
<div class="inner-content">
  <nz-tabset [nzTabBarExtraContent]="logOutTemplate" [nzSize]="'large'">
    <nz-tab [nzTitle]="domainsTemplate">
        <app-domain></app-domain>
        <ng-template #domainsTemplate>                                    
                <i nz-icon nzType="global" nzTheme="outline"></i>
                Domains &nbsp;&nbsp;
          </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="proxyTemplate">
        <app-proxy></app-proxy>
        <ng-template #proxyTemplate>                        
                <i nz-icon nzType="swap" nzTheme="outline"></i>
                Proxies &nbsp;&nbsp;
          </ng-template>
    </nz-tab>     
    <nz-tab [nzTitle]="settingsTemplate">
        <app-setting></app-setting>
        <ng-template #settingsTemplate>                        
                <i nz-icon nzType="setting" nzTheme="outline"></i>
                Settings &nbsp;&nbsp;
          </ng-template>
    </nz-tab>    
</nz-tabset>
<ng-template #logOutTemplate>
      <button nz-button class="" [nzType]="'primary'" (click)="signOut()" >
            <i nz-icon nzType="logout" nzTheme="outline"></i>
        Sign Out &nbsp;&nbsp;
      </button>   
    </ng-template>
</div>

        