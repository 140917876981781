<br><br>
<div nz-row>
  <div nz-col nzFlex="2"></div>
  <div nz-col nzFlex="1">
    <br><br><br><br>

    <nz-card style="width:25em;">

      <form nz-form class="auth-form" *ngIf="action === 'login'">
        <h3>Sign In</h3>
        <br>
        <nz-form-item>
          <nz-form-control nzErrorTip="Email">
            <nz-input-group nzPrefixIcon="mail">
              <input type="text" nz-input [(ngModel)]="email" [ngModelOptions]="{standalone: true}"
                placeholder="Email" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Password">
            <nz-input-group nzPrefixIcon="lock">
              <input type="password" nz-input [(ngModel)]="password" [ngModelOptions]="{standalone: true}"
                placeholder="Password" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" (click)="signIn()">Sign
          In</button>
        <br>
        <button nz-button class="" [nzType]="'link'" (click)="initPasswordReset()">Forgot password?</button>
        <br>
        <button nz-button class="" [nzType]="'link'">Need an account? Sign up here</button>
      </form>
      <span *ngIf="action === 'reset-password'">
        <h3>Enter your email below</h3>
        <input type="text" nz-input [(ngModel)]="reset_email" [ngModelOptions]="{standalone: true}"
          placeholder="user@example.com" />
        <br><br>
        <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'"
          (click)="resetPassword()">Reset</button>
      </span>

      <span *ngIf="action === 'verify-code'">
        <h3>Check your email</h3>
        <h4>We've sent an email with a verification code.</h4><br>
        <input type="text" nz-input [(ngModel)]="verificationCode" [ngModelOptions]="{standalone: true}"
          placeholder="Verification Code" />
        <br><br>
        <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'"
          (click)="verify()">Verify</button>
        <br>{{verificationError}}
        <button nz-button class="login-form-button login-form-margin" [nzType]="'link'"
          (click)="resendVerificationCode()">Resend verification email</button>
      </span>

      <span *ngIf="action === 'change-password'">
        <h3>Check your email</h3>
        <h4>We've sent an email with a verification code.</h4><br>
        <h4>Verification code</h4>
        <input type="text" nz-input [(ngModel)]="verificationCode" [ngModelOptions]="{standalone: true}"
          placeholder="Verification Code" />
        <br><br>
        <h4>New password</h4>
        <input type="password" nz-input [(ngModel)]="new_password" [ngModelOptions]="{standalone: true}"
          placeholder="New password" />
        <br><br>
        <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'"
          (click)="changePassword()">Change password</button>
        <br>{{verificationError}}
        <button nz-button class="login-form-button login-form-margin" [nzType]="'link'" (click)="resetPassword()">Resend
          verification email</button>
      </span>
    </nz-card>

  </div>
  <div nz-col nzFlex="2"></div>
</div>