
<div class="inner-content">
    <nz-page-header class="site-page-header" nzTitle="Settings">        
      </nz-page-header>
      <nz-tabset nzTabPosition="left">
        <nz-tab [nzTitle]="changePasswordTemplate">
            Changing password
            <ng-template #changePasswordTemplate>                                    
                    <i nz-icon nzType="lock" nzTheme="outline"></i>
                    Credentials &nbsp;&nbsp;
              </ng-template>
        </nz-tab>  
        
        <nz-tab [nzTitle]="paymentTemplate">
            Changing password
            <ng-template #paymentTemplate>                                    
                    <i nz-icon nzType="credit-card" nzTheme="outline"></i>
                    Payment &nbsp;&nbsp;
              </ng-template>
        </nz-tab>  
      </nz-tabset>
</div>

        