import { Component, OnInit } from '@angular/core';
import { CognitoUserPool,CognitoUserAttribute,AuthenticationDetails,  CognitoUser } from 'amazon-cognito-identity-js';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  action = "login"
  //action = "reset-password"

  verificationCode = ""

  isLoading: boolean = false;
  email: string = "";
  password: string = "";

  reset_email: string = ""
  new_password: string = ""

  constructor(private router: Router,private message: NzMessageService) { }

  userPool = new CognitoUserPool({
    UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
    ClientId: environment.cognitoAppClientId // Your client id here
  });

  ngOnInit(): void { }

  notify(message:string){
    const id = this.message.error(message, 
      { nzDuration: 0 }).messageId;
    setTimeout(() => {
      this.message.remove(id);
    }, 3500);
  }

  signIn(){
    if (this.email.length==0 || this.password.length==0){
      this.notify("Missing email/password") 
      return
    }

    console.log(this.email, this.password)

    let authenticationDetails = new AuthenticationDetails({
      Username: this.email,
      Password: this.password,
    });

     let userData = { Username: this.email, Pool: this.userPool };
     var cognitoUser = new CognitoUser(userData);
     cognitoUser.authenticateUser(authenticationDetails, {
       onSuccess: (result) => {
         this.router.navigate([""])
       },
       onFailure: (err) => {        
         console.log(err)
         this.message.create("error", err.message)

         if (err.message.includes("User is not confirmed.")){
            this.action = "verify-code"
         }
       },
     });

  }


  verify(){   
    var userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    // cognitoUser.forgotPassword()
    cognitoUser.confirmRegistration(this.verificationCode, true, (err, result) => {
      if (err) {
        console.log(err)            
        this.message.create("error",err.message)
        return;
      }
      this.message.create("info","Email verified successfully.")            
      this.router.navigate([""])
    });
  }

  resendVerificationCode(){
    var userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
       console.log(err)
        return;
      }
      this.message.create("info","New code sent, please use the latest code.")
    });

  }

  initPasswordReset(){
    this.action = "reset-password"
  }  

  resetPassword(){

    console.log("Resett email"+ this.reset_email)
    var userData = {
      Username: this.reset_email,
      Pool: this.userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: (data) => {       
        this.message.create('info',"Email with verification code sent to "+this.reset_email)
        this.action = "change-password"
        
      },
      onFailure: (err) => {        
        this.message.create("error", err.message)
      }});


    
  }

  changePassword(){
    var userData = {
      Username: this.reset_email,
      Pool: this.userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(this.verificationCode, this.new_password, {
			onSuccess: () => {
        console.log("im here...")
				this.message.create('success', "Password changed successfully")
        this.router.navigate([""])              
			},
			onFailure: (err) => {
			this.message.create('error',err.message)
			},
		});
  }

  saySuccess(){

  }
  sayFailed(message){

  }

}
