import { Component, OnInit } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { ApiService } from 'src/app/services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.css']
})

export class ProxyComponent implements OnInit {
  proxies = [];
  domains = [];

  listLoading = true;
  createLoading = false;

  createError = ""

  newSource = ""
  newDestination = ""


  createMode = false;

  showCreateDrawer(): void {
    this.createMode = true;
  }

  closeCreateDrawer(): void {
    this.createMode = false;
    this.newSource = ""
    this.newDestination = ""
    this.createError =""
  }

  constructor(private api: ApiService, private message: NzMessageService) { }

  ngOnInit(): void {
    //this.getDomains()
    this.getProxies()    
  }

  // getDomains(){
  //   this.api.get("domains").subscribe((data: any) => {
  //     this.listLoading = false;
  //     this.domains = data
  // })
  // }
  
  getProxies() {
    this.api.get("proxies").subscribe((data: any) => {
      this.listLoading = false;
      this.proxies = data
  })
  }

  createProxy(){
    this.createLoading = true;
    if (this.newSource.length ==0 && this.newDestination.length ==0) {
      this.createError = "Please enter a source and destination"
      return 
    }

    let payload = {
      "source": this.newSource,
      "destination": this.newDestination
    }
    this.api.set("proxies",payload).subscribe(
      (data: any) => {
      this.createLoading = false;      
      this.closeCreateDrawer()
      this.getProxies()
  },
      (error) => {
        if (error.status==200) { //False detection
          this.createLoading = false;      
          this.closeCreateDrawer()
          this.getProxies()
          return
        }
        console.log(error)
        this.createLoading = false;        
        this.createError = error.error 
      }
  )

  }

  cancel(): void {}

  notify(message:string){
    const id = this.message.loading(message, 
      { nzDuration: 0 }).messageId;
    setTimeout(() => {
      this.message.remove(id);
    }, 2500);
  }

  deleteProxy(id: string){
    this.listLoading = true 
    this.api.delete("proxy/"+id).subscribe(
      (data: any) => {
          this.listLoading = false;
          this.getProxies()
                      },
      (error: any) => {
          this.listLoading = false;

          if (error.status!=200) {
            this.notify(error.error)
            return 
          }        
          this.getProxies()  
                      }                      
  )

    
  }



}
