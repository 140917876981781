import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.guard'
import { SignUpComponent } from './pages/sign-up/sign-up.component'
import { SignInComponent } from './pages/sign-in/sign-in.component'



//import { DomainComponent } from './pages/domain/domain.component';
// import { ProxyComponent } from './pages/proxy/proxy.component';
import { ConsoleComponent } from './pages/console/console.component';

import { SettingComponent } from './pages/setting/setting.component';


const routes: Routes = [
  // // Redirect
  // { path: '', pathMatch: 'full', redirectTo: 'domains' },
  // // Domains
  // { path:  'domains', component:  DomainComponent},  
  // // Proxies
  // { path:  'proxies', component:  ProxyComponent}, 
  
  // Console
  { path:  '', component:  ConsoleComponent, canActivate: [AuthGuard]}, 

  // Auth
  { path:  'signin', component:  SignInComponent}, 
  { path:  'signup', component:  SignUpComponent}, 

  //{ path:  'setting', component:  SettingComponent, canActivate: [AuthGuard] }, 
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
