import { Component, OnInit } from '@angular/core';
import { CognitoUserPool,CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';


interface formDataInterface {
  "email": string;
};

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  action = "signup"


  email:string = '';
  password:string = '';
  name:string = '';

  verificationCode: string;
  verificationError= '';

  showForm = true

  userPool = new CognitoUserPool({
    UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
    ClientId: environment.cognitoAppClientId // Your client id here
  });



  constructor(private router: Router, 
    private message: NzMessageService) { }

  ngOnInit(): void {

  }


  signUp(){

    // Check if email or password is missing
    if (this.email.length==0 || this.password.length==0){      
      this.message.create("info","Missing email or password")
      return
    }
  // Only additional attribute we need is name
    var attributeList = []
    let attrData = {
      Name: "name",
      Value: this.name
    }
    let attribute = new CognitoUserAttribute(attrData);
    attributeList.push(attribute)

    this.userPool.signUp(this.email, this.password, attributeList, [], (
      err,
      result
    ) => {
      if (err) {
        this.message.create('error', err.message);
        console.log(err)   
        return;
      }      
      this.action = "verify-code"
      
    });
    
  }

  verify(){   
    var userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(this.verificationCode, true, (err, result) => {
      if (err) {
        console.log(err)            
        this.message.create("error",err.message)
        return;
      }
      this.message.create("info","Email verified successfully. Welcome "+this.name)            
      this.router.navigate([""])
    });
  }

  resendVerificationCode(){
    var userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
       console.log(err)
        return;
      }
      this.message.create("info","New code sent, please use the latest code.")
    });

  }

}
