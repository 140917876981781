<div class="inner-content">

      <div nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="16">
          <nz-page-header class="site-page-header" nzTitle="Domains">
            <nz-page-header-extra>
              <a nz-button nzType="primary" (click)="showCreateDrawer()"><i nz-icon nzType="plus"></i> Add new domain</a>
              <button nz-button nzType="default" (click)="getDomains()"> <i nz-icon nzType="reload"></i>Refresh</button>
            </nz-page-header-extra>
          </nz-page-header>
    
          <div class="inner-content">
            <nz-table #basicTable [nzData]="domains" [nzLoading]="listLoading" >
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                  <th>&nbsp;Bandwidth
                    <br> (Current month)
                  </th>
                  <th>&nbsp;&nbsp;&nbsp;&nbsp;Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let domain of domains">
    
                  <td *ngIf="domain.verified === 0">
                    <a nzDisabled style="color:red" nz-tooltip
                      nzTooltipTitle="Domain has not been verified yet. Please verify {{domain.name}}  before creating a proxy.">
                      <i nz-icon nzType="warning" nzTheme="outline" style="font-size: 20px;"></i> &nbsp;
                      {{domain.name}}
                
                    </a>
                  </td>
    
                  <td *ngIf="domain.verified === 1"><a style="color:black" routerLink="../domain/{{domain.name}}">
                      {{domain.name}}
                      <nz-badge nz-tooltip nzTooltipTitle="{{domain.count_proxies}} proxies created for {{domain.name}}"
                        nzStandalone nzShowZero='true'
                        [nzStyle]="{ backgroundColor: '#fff', color: 'black', boxShadow: '0 0 0 1px #d9d9d9 inset' }"
                        [nzCount]=domain.count_proxies></nz-badge>
                    </a></td>
                  <td>
                    <span *ngIf="domain.verified === 0">
                      <a nz-button nzType="link" nz-popover [nzPopoverContent]="verificationTemplate">Verify</a>
                      <ng-template #verificationTemplate>
                        <h3 style="text-align:justify">
                          <br>
                          To verify domain, edit the DNS entry <br>
                          of your domain and add a new TXT record <br>
                          with the name <span nz-typography><kbd>_brandedsurf.{{domain.name}} </kbd></span><br>
                          and value
                          <span nz-typography><kbd>{{domain.verification_token}}</kbd></span>
                          <br><br>
    
                          Click the button below to verify the domain
                          <br><br>
                          <button nz-button nzType="primary" (click)="verifyDomain(domain.id)" >Verify now</button>
                          <button nz-button nzType="link">More information</button>
                        </h3>
                      </ng-template>
                    </span>
    
                  </td>
                  <!-- <td> &nbsp;&nbsp;&nbsp;<nz-badge nzStandalone nzShowZero='true' [nzCount]=domain.count_proxies></nz-badge> </td> -->
    
                  <td>&nbsp;&nbsp;&nbsp; {{domain.bandwidth_usage}} GB</td>
                  <td>
                    <a>View</a>
                    <nz-divider nzType="vertical"></nz-divider>
    
                    <a style="color:red" nzDanger nz-popconfirm
                      nzPopconfirmTitle="Are you sure you want to delete this domain and all proxies associated with it?"
                      nzPopconfirmPlacement="top" (nzOnConfirm)="deleteDomain(domain.id)" (nzOnCancel)="cancel()">
                      Delete
                    </a>
    
    
                  </td>
                </tr>
              </tbody>
            </nz-table>
    
    
          </div>
        </div>
        <div nz-col nzXs="0" nzSm="0" nzMd="8" nzLg="8" nzXl="8">
          <nz-card>
            <nz-alert nzType="info" nzMessage="From the team"
              nzDescription="Thank you for trying out BrandedSurf beta. If you need support, please reach us at beta@brandedsurf.com ">
            </nz-alert>
          </nz-card>
    
        </div>
      </div>   
  
</div>

<!-- Add a new domain -->
<nz-drawer
      [nzBodyStyle]="{ overflow: 'auto' }"
      [nzMaskClosable]="false"
      [nzWidth]="420"
      [nzVisible]="createMode"
      nzTitle="Add new domain"
      [nzFooter]="footerTpl"
      (nzOnClose)="closeCreateDrawer()"
    >
    <form nz-form *nzDrawerContent>
      <div nz-row [nzGutter]="12">
        <div nz-col nzSpan="24">  
    <nz-alert nzType="info" 
          nzDescription=" Please enter a domain you own and control the DNS settings. The domain has to be validated before creating any proxies.">
    </nz-alert>                
    <br>
    <nz-form-item>
      <nz-form-label>Domain name </nz-form-label>
      <nz-form-control>
        <input nz-input placeholder="example.com" [(ngModel)]="newDomainName" [ngModelOptions]="{standalone: true}" />
      </nz-form-control>
    </nz-form-item>
    <br>

    <span nz-typography nzType="danger">{{createError}}</span>

        </div>       
      </div>    
    </form>
    

    <ng-template #footerTpl>
      <div style="float: right">
        <button nz-button style="margin-right: 8px;" (click)="closeCreateDrawer()">Cancel</button>
        <button nz-button nzType="primary" (click)="addDomain()">Add</button>
      </div>
    </ng-template>
  </nz-drawer>