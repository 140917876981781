<br><br>
<div nz-row>
    <div nz-col nzFlex="2"></div>
    <div nz-col nzFlex="1">
        <br><br><br><br>
        
        <nz-card style="width:25em;">
            
            <form nz-form  class="signup-form" *ngIf="action === 'signup'">
                <h3>Sign Up</h3>
                <br>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Name">
                      <nz-input-group nzPrefixIcon="user">
                        <input type="text" nz-input [(ngModel)]="name"[ngModelOptions]="{standalone: true}"  placeholder="Name" />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                <nz-form-item>
                  <nz-form-control nzErrorTip="Email">
                    <nz-input-group nzPrefixIcon="mail">
                      <input type="text" nz-input [(ngModel)]="email"[ngModelOptions]="{standalone: true}"  placeholder="Email" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-control nzErrorTip="Password">
                    <nz-input-group nzPrefixIcon="lock">
                      <input type="password" nz-input [(ngModel)]="password" [ngModelOptions]="{standalone: true}" placeholder="Password" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>                           
                <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" (click)="signUp()">Sign Up</button>                
                <button nz-button class="" [nzType]="'link'">Already have an account? Sign In here</button>
              </form>
              <span *ngIf="action === 'verify-code'">
              <h3>Check your email</h3>
              <h4>We've sent an email with a verification code.</h4><br>
              <input type="text" nz-input [(ngModel)]="verificationCode" [ngModelOptions]="{standalone: true}" placeholder="Verification Code" />
              <br><br>
              <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" (click)="verify()">Verify</button>
              <br>{{verificationError}}
              <button nz-button class="login-form-button login-form-margin" [nzType]="'link'" (click)="resendVerificationCode()">Resend verification email</button>
            </span>
        </nz-card>

    </div>
    <div nz-col nzFlex="2"></div>
  </div>


